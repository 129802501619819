<template>
  <div>
    <el-form
      v-loading="loading"
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :labelWidth="$vars.forms.label_width"
      :name="$options.name"
      :rules="rules"
      :disabled="disabled"
      ref="form"
      :model="item"
      class="camera-geopositipn-form"
    >
      <el-form-item :label="$tf('latitude')" prop="latitude">
        <camera-coordinate-input name="latitude" v-model="item.latitude" />
      </el-form-item>

      <el-form-item :label="$tf('longitude')" prop="longitude">
        <camera-coordinate-input name="longitude" v-model="item.longitude" />
      </el-form-item>

      <el-form-item :label="$tf('azimuth')" prop="azimuth">
        <camera-coordinate-input name="azimuth" v-model="item.azimuth" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CameraCoordinateInput from '../camera-coordinate-input';
import { getNumberValidator } from '@/apps/common/validator';

const baseRules = {
  azimuth: [
    {
      validator: getNumberValidator({ required: false, min: 0, max: 359.9, float: true }),
      tmessage: 'error.field.ufloat',
      trigger: 'change'
    }
  ],
  latitude: [
    {
      validator: getNumberValidator({ required: false, min: -90, max: 90, float: true }),
      tmessage: 'error.field.ufloat',
      trigger: 'change'
    }
  ],
  longitude: [
    {
      validator: getNumberValidator({ required: false, min: -180, max: 180, float: true }),
      tmessage: 'error.field.ufloat',
      trigger: 'change'
    }
  ]
};

export default {
  name: 'geoposition',
  props: {
    state: Object,
    item: Object,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CameraCoordinateInput
  },
  data: function () {
    return {
      rules: this.$applyRuleMessages(baseRules)
    };
  },
  mounted() {
    this.$emit('getForm', this.$refs.form);
  }
};
</script>
