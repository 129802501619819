<template>
  <el-input :name="name" v-model="model" />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'CameraCoordinateInput',
  props: {
    name: { type: String, required: true },
    value: { type: [String, Number], default: null }
  }
})
export default class extends Vue {
  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', computeNewInputValue(value));
  }
}

function computeNewInputValue(value) {
  return value !== '' ? value : null;
}
</script>
